.LoginTitle {
    color: rgb(232, 23, 93, 1);
}

.LoginFormSection {
    margin-bottom: 30px;
}

.LoginErrorMessage {
    color: rgb(232, 23, 93, 1);
}

.LoginFormWrapper {
    width: 20%;
    margin: 0 auto;
}

.LoginLabel {
    color: white;
    font-weight: bold;
}

.LoginInput {
    width: 100%;
    padding: 10px;
}
  
.LoginFormIcon {
    padding: 10px;
    min-width: 10px;
    color: red;
    right: 0;
    position: absolute;
}
